const modalDialog = {
  active: false,
  type: 'alert', // alert or confirmation
  title: null,
  text: null,
  confirmText: 'Confirmar',
  cancelText: 'Cancelar',
  size: 'sm',
  fn: null,
  cancelFn: null,
};

const state = () => ({
  isProgressBar: false,

  progressTracker: [
    {
      step: 1,
      isCompleted: false,
      isCurrent: false,
      title: 'Selecionar especialidade',
      route: {
        unlogged: 'AppointmentSchedulingSpecialty',
        logged: 'UserSchedulingSpecialty',
      },
      nextRoute: {
        unlogged: 'AppointmentSchedulingPaymentMethod',
        logged: 'UserSchedulingPaymentMethod',
      },
    },
    {
      step: 2,
      isCompleted: false,
      isCurrent: false,
      title: 'Método de pagamento',
      route: {
        unlogged: 'AppointmentSchedulingPaymentMethod',
        logged: 'UserSchedulingPaymentMethod',
      },
      nextRoute: {
        unlogged: 'AppointmentSchedulingDate',
        logged: 'UserSchedulingDate',
      },
    },
    {
      step: 3,
      isCompleted: false,
      isCurrent: false,
      title: 'Escolher data e horário',
      route: {
        unlogged: 'AppointmentSchedulingDate',
        logged: 'UserSchedulingDate',
      },
      nextRoute: {
        unlogged: 'AppointmentSchedulingCheckout',
        logged: 'UserSchedulingCheckout',
      },
    },
    {
      step: 4,
      isCompleted: false,
      isCurrent: false,
      title: 'Identificação e pagamento',
      route: {
        unlogged: 'AppointmentSchedulingCheckout',
        logged: 'UserSchedulingCheckout',
      },
      nextRoute: {
        unlogged: 'AppointmentSchedulingSummary',
        logged: 'UserSchedulingSummary',
      },
    },
  ],

  modalDialog: { ...modalDialog },
});

const getters = {
  isProgressBar: (state) => state.isProgressBar,

  progressTracker: (state) => state.progressTracker.sort((a, b) => a.step >= b.step),

  modalDialog: (state) => state.modalDialog,
};

const actions = {
  toggleProgressBar(context, status = true) {
    context.commit('mutateProgressBar', status);
  },

  setCurrentProgressTrackerStep(context, commit) {
    context.commit('mutateCurrentProgressTrackerStep', commit);
  },

  completeProgressTrackerStep(context, commit) {
    context.commit('mutateCompletedProgressTrackerStep', commit);
  },

  resetProgressTrackerStep(context) {
    context.commit('mutateResetProgressTracker');
  },

  openModalDialog(context, commit) {
    context.commit('mutateOpenModalDialog', commit);
  },

  closeModalDialog(context) {
    context.commit('mutateCloseModalDialog');
  },
};

const mutations = {
  mutateProgressBar(state, payload) {
    state.isProgressBar = payload;
  },

  mutateCurrentProgressTrackerStep(state, step) {
    state.progressTracker
      .forEach((item) => {
        item.isCurrent = false;
      });

    state.progressTracker
      .find((item) => item.step === step)
      .isCurrent = true;
  },

  mutateCompletedProgressTrackerStep(state, payload) {
    const { step, status } = payload;

    state.progressTracker
      .find((item) => item.step === step)
      .isCompleted = status;
  },

  mutateResetProgressTracker(state) {
    state.progressTracker.forEach((step) => {
      step.isCurrent = false;
    });
  },

  mutateOpenModalDialog(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if ([key] in state.modalDialog) {
        state.modalDialog[key] = value;
      }
    });

    state.modalDialog.active = true;
  },

  mutateCloseModalDialog(state) {
    state.modalDialog = { ...modalDialog };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
