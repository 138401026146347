import store from '@/store';

const guard = (to, from, next) => {
  const progressTracker = store.getters['ui/progressTracker'];

  if (
    from.name === 'LandingPage'
    && (to.name === 'AppointmentScheduling' || to.name === 'AppointmentSchedulingSpecialty')
    && progressTracker[0].isCompleted
  ) {
    next({ name: 'AppointmentSchedulingDate' });
    return;
  }

  if (
    (to.name === 'AppointmentScheduling' || to.name === 'AppointmentSchedulingSpecialty')
    && from.name !== 'LandingPage'
  ) {
    store.dispatch('scheduling/resetAppointment');
    next();
    return;
  }

  if (progressTracker.some((step) => step.route.unlogged === to.name)) {
    const stepTo = progressTracker.find((step) => step.route.unlogged === to.name);

    if (stepTo.step > 1) {
      const stepFrom = progressTracker.find((step) => step.step === stepTo.step - 1);

      if (stepFrom.isCompleted) {
        next();
        return;
      }

      next({ name: stepFrom.route.unlogged });
    }
    next();
    return;
  }

  if (
    to.name === 'AppointmentSchedulingSummary'
    && !progressTracker.every((step) => step.isCompleted)
  ) {
    next({ name: progressTracker[0].route.unlogged });
    return;
  }

  next();
};

const routes = [
  {
    path: '/agendamento',
    name: 'AppointmentScheduling',
    component: () => import('../views/appointmentScheduling/AppointmentScheduling.vue'),
    beforeEnter: guard,
    redirect: { name: 'AppointmentSchedulingSpecialty' },
    children: [
      {
        path: 'especialidade-medica',
        name: 'AppointmentSchedulingSpecialty',
        component: () => import('../components/appointmentScheduling/AppointmentSpecialty.vue'),
        beforeEnter: guard,
      },

      {
        path: 'metodo-pagamento',
        name: 'AppointmentSchedulingPaymentMethod',
        component: () => import('../components/appointmentScheduling/AppointmentPaymentMethod.vue'),
        beforeEnter: guard,
      },

      {
        path: 'data-horario',
        name: 'AppointmentSchedulingDate',
        component: () => import('../components/appointmentScheduling/AppointmentDate.vue'),
        beforeEnter: guard,
      },

      {
        path: 'identificacao-pagamento',
        name: 'AppointmentSchedulingCheckout',
        component: () => import('../components/appointmentScheduling/AppointmentCheckout.vue'),
        beforeEnter: guard,
      },

      {
        path: 'resumo-consulta',
        name: 'AppointmentSchedulingSummary',
        component: () => import('../components/appointmentScheduling/AppointmentSummary.vue'),
        beforeEnter: guard,
      },
    ],
  },
];

export default routes;
